.scorecard {
    color: white;
    font-family: 'Arial', sans-serif;
    padding: 20px;
    max-width: 600px;
    margin: 20px auto;
    background: rgb(50, 50, 50, 0.8); /* Solid dark gray */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    position: relative; /* Needed for z-index to take effect */
    z-index: 5; /* Adjust this value based on your needs */
}



.r-line {
    position: fixed;
    width: 2px;
    height: 100%;
    opacity: 0.5;
}

.r-line-one {
    background-image: linear-gradient(to bottom, #ea2f86 75%, black);
    height: 18%; 
    left: 25%; 
    top: 0;
}
.r-line-two { 
    background-image: linear-gradient(to bottom, #f09c0a 75%, black);
    height: 23%;
    left: 35%; 
    top: 0;
}
.r-line-three { 
    background-image: linear-gradient(to bottom, #fae000 75%, black);
    height: 20%;
    left: 45%; 
    top: 0;
}
.r-line-four { 
    background-image: linear-gradient(to bottom, #93e223 75%, black);
    height: 24%;
    left: 55%; 
    top: 0;
}
.r-line-five { 
    background-image: linear-gradient(to bottom, #4070d3 75%, black);
    height: 19%;
    left: 65%; 
    top: 0;
}
.r-line-six { 
    background-image: linear-gradient(to bottom, #493c9e 75%, black);
    height: 17%;
    left: 75%; 
    top: 0;
}
.r-line-seven { 
    background-image: linear-gradient(to top, #493c9e 65%, black);
    height: 38%;
    left: 75%; 
    bottom: 0;
}
.r-line-eight { 
    background-image: linear-gradient(to top, #4070d3 65%, black);
    height: 35%;
    left: 65%; 
    bottom: 0;
}
.r-line-nine { 
    background-image: linear-gradient(to top, #93e223 65%, black);
    height: 37%;
    left: 55%; 
    bottom: 0;
}
.r-line-ten { 
    background-image: linear-gradient(to top, #fae000 65%, black);
    height: 33%;
    left: 45%; 
    bottom: 0;
}
.r-line-eleven { 
    background-image: linear-gradient(to top, #f09c0a 65%, black);
    height: 38%;
    left: 35%; 
    bottom: 0;
}
.r-line-twelve { 
    background-image: linear-gradient(to top, #ea2f86 65%, black);
    height: 35%;
    left: 25%; 
    bottom: 0;
}
.score-text {
    opacity: 0;
    transition: opacity 1s ease-in;
}

.fade-in {
    opacity: 1;
}