.loading-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    height: 100vh; /* Full viewport height */
    width: 100vw; /* Full viewport width */
    position: fixed; /* Or 'absolute' if you need it relative to a positioned ancestor */
    top: 32vh;
    left: 0;
}


.loading-circle-one {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: relative;
    background: conic-gradient(#ea2f86 0%, #f09c0a 15%, #fae000 30%, #93e223 45%, #4070d3 60%, #493c9e 75%, black 100%);
    animation: spin 2s linear infinite;  /* Rotating animation */
}

.loading-inner-circle {
    position: absolute;
    width: 95px;
    height: 95px;
    background-color: black;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
