.question-container {
    display: flex;  /* Establishes a flex container */
    flex-direction: column;  /* Stacks children vertically */
    align-items: center;  /* Centers children horizontally within the container */
    justify-content: center;  /* Centers children vertically within the container */
}

.prompt-title
{
    font-size: 12px;
}

.round-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centers children horizontally in the container */
    position: fixed; /* Use fixed if you want it always visible at the top of the viewport */
    top: 1vh; /* Aligns the top edge of the container with the top of the viewport */
    left: 0; /* Aligns the left edge of the container with the left of the viewport */
    right: 0; /* Ensures the container extends right to match the viewport width */
    text-align: center; /* Ensures text inside the container is centered */
    z-index: 1000; /* Ensures the container stays on top of other content */
}

.round-container h6 {
    margin: 0;
    padding: 10px 0;
}


@keyframes rotate-hand {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

.q-line-seven { 
    background-image: linear-gradient(to top, #493c9e 65%, black);
    height: 25%;
    left: 75%; 
    bottom: 0;
}
.q-line-eight { 
    background-image: linear-gradient(to top, #4070d3 65%, black);
    height: 22%;
    left: 65%; 
    bottom: 0;
}
.q-line-nine { 
    background-image: linear-gradient(to top, #93e223 65%, black);
    height: 24%;
    left: 55%; 
    bottom: 0;
}
.q-line-ten { 
    background-image: linear-gradient(to top, #fae000 65%, black);
    height: 20%;
    left: 45%; 
    bottom: 0;
}
.q-line-eleven { 
    background-image: linear-gradient(to top, #f09c0a 65%, black);
    height: 25%;
    left: 35%; 
    bottom: 0;
}
.q-line-twelve { 
    background-image: linear-gradient(to top, #ea2f86 65%, black);
    height: 22%;
    left: 25%; 
    bottom: 0;
}

.slider {
    -webkit-appearance: none;
    width: 80%;
    height: 5px;
    border-radius: 10px;
    background: linear-gradient(to right, #ea2f86b4, #f09c0ab4, #fae000b4, #93e223b4, #4070d3b4, #493c9eb4);
    outline: none;
    opacity: 1;
    margin: 20px auto;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 22px;
    border-radius: 50%;
    background: var(--thumb-color);
    cursor: pointer;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.6);
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: var(--thumb-color);
    cursor: pointer;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.6);
}

.slider-labels {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
    margin-top: -10px;
    font-size: 14px;
}

.slider-value {
    margin-top: 10px;
    font-size: 1.2em;
}

.fixed-height {
    height: 2em; /* Adjust this value based on the line height and font size to ensure it fits two lines */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
